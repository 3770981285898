export const environment = {
    production: false,
    featureFlagRefreshTimeMs: 30000,
    applicationInsights: {
      connectionString: 'InstrumentationKey=d881e6eb-15ab-40e8-9af6-4b14a6df223c;IngestionEndpoint=https://southafricanorth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://southafricanorth.livediagnostics.monitor.azure.com/;ApplicationId=d883c448-6c23-438c-927c-d017ee7d8d4d'
    },
    support: {
      email: 'support@1Nebula.com'
    },
    emailImageDefaults: {
      activateFailed: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/activation%20failed%20RED-01.png',
      activateSuccess: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/activation%20success.png',
      subscriptionCancelled: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/cancelled2-01.png',
      subscriptionReinstated: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/reinstate-02-01.png',
      subscriptionSuspended: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/suspended.png',
      planChangeInProgress: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/change%20in%20progress.png',
      planChangeFailed: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/planchangeerror-01.png',
      planChangeSuccess: 'https://48billingprodstorage.blob.core.windows.net/tenant-service-assets/plan%20change%20success.png',
    },
    fullstory: {
      isEnabled: false,
      orgId: 'FXWTB'
    },
    stactizeSignupUrl: 'https://signupdev.surgemarketplace.com'
  };